exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-layouts-AdminLayout-dashboard {\n  width: 100%;\n  height: 100%;\n}\n.src-layouts-AdminLayout-sider {\n  height: 100%;\n  padding-bottom: 48px;\n}\n.src-layouts-AdminLayout-sider.ant-layout-sider-collapsed .src-layouts-AdminLayout-logoText {\n  display: none;\n}\n.src-layouts-AdminLayout-sider .ant-layout-sider-children {\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n.src-layouts-AdminLayout-sider .ant-layout-sider-children::-webkit-scrollbar {\n  width: 4px;\n  background-color: #000;\n}\n.src-layouts-AdminLayout-sider .ant-layout-sider-children::-webkit-scrollbar-thumb {\n  background-color: #aaa;\n}\n.src-layouts-AdminLayout-logo {\n  height: 64px;\n  line-height: 64px;\n  color: #fff;\n  text-align: center;\n  font-size: 20px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.src-layouts-AdminLayout-logoImg {\n  width: 38px;\n  height: 38px;\n  vertical-align: middle;\n}\n.src-layouts-AdminLayout-logoText {\n  margin-left: 4px;\n  font-family: cursive;\n  vertical-align: middle;\n}\n.src-layouts-AdminLayout-container {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n}\n.src-layouts-AdminLayout-header {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding: 0;\n  color: #fff;\n}\n.src-layouts-AdminLayout-headerLeft {\n  background-color: transparent;\n}\n.src-layouts-AdminLayout-headerRight {\n  display: flex;\n}\n.src-layouts-AdminLayout-headerUserItem {\n  display: block;\n  padding: 0 15px;\n  color: #fff;\n}\n.src-layouts-AdminLayout-content {\n  flex-grow: 1;\n  width: 100%;\n  padding: 20px;\n  overflow-x: hidden;\n  overflow-y: auto;\n  -webkit-overflow-scrolling: touch;\n}\n", ""]);

// exports
exports.locals = {
	"dashboard": "src-layouts-AdminLayout-dashboard",
	"sider": "src-layouts-AdminLayout-sider",
	"logoText": "src-layouts-AdminLayout-logoText",
	"logo": "src-layouts-AdminLayout-logo",
	"logoImg": "src-layouts-AdminLayout-logoImg",
	"container": "src-layouts-AdminLayout-container",
	"header": "src-layouts-AdminLayout-header",
	"headerLeft": "src-layouts-AdminLayout-headerLeft",
	"headerRight": "src-layouts-AdminLayout-headerRight",
	"headerUserItem": "src-layouts-AdminLayout-headerUserItem",
	"content": "src-layouts-AdminLayout-content"
};