import { atom, DefaultValue, selector } from 'recoil';

import parseJSON from '@/utils/parseJSON';

export const userState = atom({
  key: 'userState',
  default: parseJSON(localStorage[STORE_KEY], false),
  effects_UNSTABLE: [
    ({onSet}) => {
      onSet(newState => {
        if (newState) {
          localStorage[STORE_KEY] = JSON.stringify(newState);
        } else {
          delete localStorage[STORE_KEY];
        }
      })
    }
  ],
});
