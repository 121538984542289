import { useRecoilValue } from 'recoil';
import { useState, useEffect, useCallback, createElement } from 'react';

import { userState } from '@/stores/userStore';

export function useCollapse() {
  const [collapsed, setCollapsed] = useState(localStorage.ui_collapsed === '1');
  const toggle = useCallback(() => {
    let v = !collapsed;
    setCollapsed(v);
    localStorage.ui_collapsed = Number(v);
  }, [collapsed, setCollapsed]);

  return [collapsed, toggle];
}

let menuData = null;

export function useMenuData() {
  const user = useRecoilValue(userState);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      if (!menuData) {
        import('@/config/admin-menus.json').then((res) => res.default).then((json) => {
          const parser = (data, key) => {
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              if (item.icon) {
                item.icon = createElement(require(`@ant-design/icons/es/icons/${item.icon}`).default);
              }
              item.key = key ? `${key}/${item.key}` : `/${item.key}`;
              if (item.children) {
                parser(item.children, item.key);
              }
            }
          }

          parser(json);
          setData(json);
          menuData = json;
        });
      } else {
        setData(menuData);
      }
    }
  }, [user]);

  return data;
}
