import { message } from 'antd';
import { useRecoilState } from 'recoil';
import { useState, useEffect, useCallback } from 'react';

import request from '@/utils/request';
import { userState } from '@/stores/userStore';

export function useGetUserInfo() {
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if (user) {
      request.call('Manager.GetManagerInfo', {})
        .then((res) => {
          const json = res.data;
          const info = json.Data;
          if (json.Error || !info) {
            delete localStorage[STORE_KEY];
            location.href = '/login';
          } else {
            setUser((preState) => ({...preState, Info: info}));
          }
        });
    }
  }, [user?.Info?.ID || 0]);
}

export function useUserLogout() {
  const [loading, setLoading] = useState(false);
  const onLogout = useCallback((evt) => {
    evt.preventDefault();

    setLoading(true);
    request.call('Manager.Logout', {})
      .then((res) => {
        const json = res.data;
        if (json.Error) {
          message.error(json.Error.detail);
        } else {
          setTimeout(() => {
            delete localStorage[STORE_KEY];
            location.href = '/login';
          });
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return [loading, onLogout];
}
