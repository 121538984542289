import { lazy, Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader';
import zhCN from 'antd/es/locale/zh_CN';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import styles from '@/assets/less/basic.less';
import { useStyles } from '@/hooks/useStyles';
import Spinner from '@/components/Spinner';
import BasicLayout from '@/layouts/BasicLayout';


const LoginPage = lazy(() => import(/* webpackChunkName: "pages/login" */ '@/pages/LoginPage'));

function App({component}) {
  useStyles(styles);

  return (
    <ConfigProvider locale={zhCN}>
      <RecoilRoot>
        <Router>
          <BasicLayout>
            <Suspense fallback={<Spinner size="large" />}>
              <Switch>
                <Route path="/login" component={LoginPage} exact />
                <Route path="/" component={component} />
              </Switch>
            </Suspense>
          </BasicLayout>
        </Router>
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default hot(module)(App);
