import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '@/components/Spinner';
import AdminLayout from '@/layouts/AdminLayout';

// 统计板面
const DashboardPage = lazy(() => import(/* webpackChunkName: "pages/dashboard" */ '@/pages/DashboardPage'));

// 用户管理
const UserListPage = lazy(() => import(/* webpackChunkName: "pages/user/users" */ '@/pages/UserListPage'));
const UserLogListPage = lazy(() => import(/* webpackChunkName: "pages/user/logs" */ '@/pages/UserLogListPage'));

// 设备管理
const DeviceListPage = lazy(() => import(/* webpackChunkName: "pages/device/devices" */ '@/pages/DeviceListPage'));

// 店铺管理
const ShopListPage = lazy(() => import(/* webpackChunkName: "pages/shop/shops" */ '@/pages/ShopListPage'));

// 财务管理
const RedPacketListPage = lazy(() => import(/* webpackChunkName: "pages/accounting/red-packets" */ '@/pages/RedPacketListPage'));
const WithdrawRebateListPage = lazy(() => import(/* webpackChunkName: "pages/accounting/withdraw-rebate" */ '@/pages/WithdrawRebateListPage'));

// 管理员管理
const ManagerListPage = lazy(() => import(/* webpackChunkName: "pages/manager/managers" */ '@/pages/ManagerListPage'));
const ManagerRolesPage = lazy(() => import(/* webpackChunkName: "pages/manager/roles" */ '@/pages/ManagerRolesPage'));
const ManagerLogListPage = lazy(() => import(/* webpackChunkName: "pages/manager/logs" */ '@/pages/ManagerLogListPage'));

// 系统管理
const SettingOptionsPage = lazy(() => import(/* webpackChunkName: "pages/setting/options" */ '@/pages/SettingOptionsPage'));

// 其它
const NotFoundPage = lazy(() => import(/* webpackChunkName: "pages/404" */ '@/pages/NotFoundPage'));

export default function AppAdmin() {
  return (
    <AdminLayout>
      <Suspense fallback={<Spinner size="large" />}>
        <Switch>
          <Redirect from="/" to="/dashbaord" exact />
          <Route path="/dashbaord" component={DashboardPage} exact />

          {/* 用户管理 */}
          <Route path="/user/users" component={UserListPage} exact />
          <Route path="/user/logs" component={UserLogListPage} exact />

          {/* 店铺管理 */}
          <Route path="/device/devices" component={DeviceListPage} exact />

          {/* 店铺管理 */}
          <Route path="/shop/shops" component={ShopListPage} exact />

          {/* 财务管理 */}
          <Route path="/finance/red-packets" component={RedPacketListPage} exact />
          <Route path="/finance/withdraw-rebate" component={WithdrawRebateListPage} exact />

          {/* 管理员管理 */}
          <Route path="/manager/managers" component={ManagerListPage} exact />
          <Route path="/manager/roles" component={ManagerRolesPage} exact />
          <Route path="/manager/logs" component={ManagerLogListPage} exact />

          {/* 系统管理 */}
          <Route path="/setting/options" component={SettingOptionsPage} exact />

          {/* 其它 */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AdminLayout>
  );
}
