import cx from 'classnames';
import { trim } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { Layout, Menu, Dropdown } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './style.less';
import { useStyles } from '@/hooks/useStyles';
import { userState } from '@/stores/userStore';
import { useGetUserInfo, useUserLogout } from '@/hooks/useUser';
import { useCollapse, useMenuData } from './hooks';

const defaultSelectedKeys = trim(location.pathname, '/') || 'dashbaord';

function Sider() {
  const history = useHistory();
  const menus = useMenuData();
  const { pathname } = useLocation();
  const path = trim(pathname, '/').split('/').slice(0, 2).join('/');
  const [collapsed, setCollapsed] = useCollapse();

  return (
    <Layout.Sider
      className={styles.locals.sider}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className={styles.locals.logo}>
        <img
          className={styles.locals.logoImg}
          src={require('@/assets/images/logo.png')}
        />
        <span className={styles.locals.logoText}>共饮科技</span>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['/' + defaultSelectedKeys.split('/').shift()]}
        defaultSelectedKeys={['/' + defaultSelectedKeys]}
        selectedKeys={['/' + path]}
        items={menus}
        onClick={({ key }) => history.push(key)}
      />
    </Layout.Sider>
  );
}

function Header({ user }) {
  const [/* loading */, onLogout] = useUserLogout();

  return (
    <Layout.Header className={styles.locals.header}>
      <div className={styles.locals.headerLeft} />
      <div className={styles.locals.headerRight}>
        <Dropdown
          dropdownRender={() =>
            <Menu>
              <Menu.Item key="logout">
                <a
                  onClick={onLogout}
                >
                  退出登录
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <a
            className={styles.locals.headerUserItem}
            onClick={e => e.preventDefault()}
          >
            {user.Info.Username}
          </a>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}

export default function AdminLayout({ className, children, ...nextProps }) {
  useStyles(styles);
  useGetUserInfo();

  const history = useHistory();
  const user = useRecoilValue(userState);
  if (!user) {
    if (user === false) {
      history.replace('/login');
    }

    return null;
  }

  return (
    <Layout
      {...nextProps}
      className={cx(styles.locals.dashboard, className)}
    >
      <Sider />
      <Layout className={styles.locals.container}>
        <Header user={user} />
        <Layout.Content className={styles.locals.content}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
