import axios from 'axios';
import jsonpipe from 'jsonpipe';

const request = axios.create({
  baseURL: SERVICE_API,
  headers: {
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

request.call = function(method, data, config) {
  return this.post(`v1/gw/rpc?method=${encodeURIComponent(method)}`, data, config);
};

request.pipe = function(method, options) {
  const url = `${request.defaults.baseURL}v1/gw/stream?method=${encodeURIComponent(method)}`;
  const config = Object.assign({
    delimiter: '\n',
    method: 'POST',
    headers: {},
    withCredentials: false,
  }, options);

  const token = getToken();
  if (token) {
    config.headers['X-Token'] = token;
  }

  if (typeof(config.data) === 'object') {
    config.data = JSON.stringify(config.data);
  }

  return jsonpipe.flow(url, config);
};

request.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers['X-Token'] = token;
  }

  delete config.headers['X-Requested-With'];

  return config;
});

function getToken() {
  const userStr = localStorage[STORE_KEY];
  let userData = null;

  if (userStr) {
    try {
      userData = JSON.parse(userStr);
    } catch (err) {}
  }

  if (userData) {
    return userData.Token;
  }

  return null;
}

export default request;
