exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-Spinner-spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"spinner": "src-components-Spinner-spinner"
};